<script>
export default {
    name: "Header"
}
</script>

<template>
    <div class="navbar">
        <img src="../assets/logo.svg" class="logo">
        <div class="navbar-right-container">
            <div class="navbar-language">{{__('Русский')}}</div>
            <div class="navbar-button-login">{{__('Войти')}}</div>
            <div class="navbar-button-download">{{__('Скачать')}}</div>
        </div>
    </div>
</template>

<style>
.navbar {
    background: var(--bg-secondary);
    width: 100% ;

    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.navbar-right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navbar-button-login {
    background: var(--button-primary);
    font-weight: 600;

    user-select: none;
}

.navbar-button-download {
    background: var(--button-secondary);
    font-weight: 600;
    color: var(--text-secondary);

    user-select: none;
}

@media (max-width: 767px) { /* стили для xs-устройств */

}

@media (min-width: 768px) and (max-width: 991px) { /* стили для sm-устройств */

}

@media (min-width: 991px) and (max-width: 1495px) { /* стили для md-устройств */
    .navbar {
        height: 72px;
        padding: 0px 44px 0px 44px;
    }

    .logo {
        height: 32px;
        width: 32px;
    }

    .navbar-right-container {
        gap: 12px;
    }

    .navbar-button-login {
        padding: 6px 14px;
        border-radius: 4px;
    }

    .navbar-button-download {
        padding: 6px 14px;
        border-radius: 4px;
    }
}

@media (min-width: 1495px) { /* lg устройства */
    .navbar {
        height: 72px;
        padding: 0px 52px 0px 52px;
    }

    .logo {
        height: 36px;
        width: 36px;
    }

    .navbar-right-container {
        gap: 16px;
    }

    .navbar-button-login {
        padding: 7px 18px;
        border-radius: 6px;
    }

    .navbar-button-download {
        padding: 7px 18px;
        border-radius: 6px;
    }
}
</style>