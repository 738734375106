<template>
    <router-view/>
    <create-account></create-account>
    <Footer></Footer>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <link rel="stylesheet" href="./font/stylesheet.css">
</template>

<style>
html,
body {
    height: 100%;
    overflow: hidden;
    touch-action: manipulation;
    user-select: none;

    scrollbar-color: #0077FF #111111;
    scrollbar-width: thin;
    position: relative;

    background: #FCFDFF;

    font-family: 'TT Interfaces';

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    letter-spacing: -0.03em;
    font-weight: 600;

    margin: 0;
}

</style>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CreateAccount from "@/pages/CreateAccount.vue";
export default {
    components: {CreateAccount, Footer, Header}
}
</script>