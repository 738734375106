<script>
export default {
    name: 'CreateAccount',
    data() {
        return {
            isTelegramLoaded: false,
            telegramId: null,
            accountExists: false,
            errorMessage: '',
            referralLink: '',
            inviteesCount: 0, // Количество приглашённых пользователей
            referrerId: null, // ID пригласившего пользователя
        };
    },
    methods: {
        async checkAccountStatus() {
            if (this.telegramId !== null) {
                try {
                    const response = await fetch('https://splitly.app:3000/api/users/check', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ telegramId: this.telegramId }),
                    });

                    if (!response.ok) {
                        throw new Error('Ошибка сети: ' + response.status);
                    }

                    const data = await response.json();
                    this.accountExists = data.exists;

                    if (this.accountExists) {
                        const userInfo = await fetch(`https://splitly.app:3000/api/users/${this.telegramId}`);
                        const userData = await userInfo.json();
                        this.referralLink = `https://t.me/splitlystartbot/splitly?start=ref_${this.telegramId}`;
                        this.inviteesCount = userData.inviteesCount;
                    }
                } catch (error) {
                    console.error('Ошибка при проверке аккаунта:', error);
                    this.errorMessage = 'Ошибка при проверке аккаунта';
                }
            } else {
                this.errorMessage = 'Telegram ID не найден';
            }
        },
        async handleButtonClick() {
            if (!this.accountExists) {
                await this.createAccount(); // Создаем аккаунт, если его нет
            }
        },
        async createAccount() {
            if (this.telegramId !== null) {
                try {
                    console.log('Создание аккаунта с ID:', this.telegramId);
                    console.log('Реферальный ID:', this.referrerId); // Лог для отладки

                    const response = await fetch('https://splitly.app:3000/api/users', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ telegramId: this.telegramId, referrerId: this.referrerId || undefined }), // Передача undefined если нет referrerId
                    });

                    if (!response.ok) {
                        const errorText = await response.text();
                        throw new Error('Ошибка сети: ' + response.status + ' ' + errorText);
                    }

                    const data = await response.json();
                    console.log('Пользователь создан:', data);
                    this.accountExists = true;
                    this.referralLink = `https://t.me/splitlystartbot/splitly?start=ref_${this.telegramId}`;
                    this.inviteesCount = 0; // Сбрасываем количество приглашённых, так как пользователь только что зарегистрировался
                } catch (error) {
                    console.error('Ошибка при создании аккаунта:', error);
                    this.errorMessage = 'Ошибка при создании аккаунта: ' + error.message;
                }
            } else {
                console.error('Telegram ID не найден');
                this.errorMessage = 'Ошибка при создании аккаунта: Telegram ID не найден';
            }
        }
    },
    mounted() {
        // Получаем реферальный ID из параметра tgWebAppStartParam
        const urlParams = new URLSearchParams(window.location.search);
        this.referrerId = urlParams.get('tgWebAppStartParam')?.replace('ref_', '') || '';
        console.log('Referrer ID:', this.referrerId);

        // Логика получения Telegram ID
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.ready();
            this.isTelegramLoaded = true;
            const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
            this.telegramId = initDataUnsafe.user?.id || 1; // Используем 1 как заглушку по умолчанию
            console.log('Telegram ID:', this.telegramId);
        } else {
            console.warn('Telegram WebApp API недоступен. Используем заглушку.');
            this.telegramId = 1; // Заглушка для тестирования в браузере
        }

        this.checkAccountStatus(); // Проверяем статус аккаунта при инициализации
    }
};
</script>

<template>
    <div v-if="!accountExists && !errorMessage" class="create-account">
        <section class="logo">
            <img src="../assets/logo.svg" class="logo-icon" alt="Logo">
        </section>
        <section class="hello-text">
            <div class="hello-main-text">Место, где половинки становятся целыми</div>
            <div class="hello-description">
                Знакомства, общение, поиск друзей или партнера, все это мы постарались уместить тут
            </div>
        </section>
        <section class="start-button-section">
            <div class="start_button" @click="handleButtonClick">
                Создать аккаунт
            </div>
        </section>
    </div>

    <!-- Показываем сообщение если аккаунт уже существует -->
    <div v-if="accountExists" class="account-exists">
        <p>Ваш реферальный код: <a :href="referralLink" target="_blank">{{ referralLink }}</a></p>
        <p>Количество приглашённых пользователей: {{ inviteesCount }}</p>
    </div>

    <!-- Показываем сообщение если произошла ошибка -->
    <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
    </div>
</template>

<style scoped>
    .create-account {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../assets/images/create-account.png);

        height: 100%;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hello-text {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
    }

    .hello-main-text {
        font-weight: 600;
        line-height: 115%;
        letter-spacing: -0.03em;
        color: #000;
    }

    .hello-description {
        color: #404040;
    }

    .start-button-section {
        position: fixed;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .start_button {
        background: #000e08;
        color: #fff;

        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;
    }

@media (min-width: 360px) {
    .logo {
        width: 100%;
        height: 97px;
    }

    .logo-icon {
        height: 37px;
        width: 138px;
    }

    .hello-text {
        width: 100%;
        height: 384px;

        gap: 18px;
    }

    .hello-main-text {
        font-size: 60px;
        line-height: 120%;
        width: 313px;
    }

    .hello-description {
        font-size: 15px;
        line-height: 173%;

        width: 313px;
    }

    .start-button-section {
        bottom: 33px;
    }

    .start_button {
        height: 56px;
        width: 314px;

        border-radius: 20px;
        font-size: 18px;

        bottom: 23px;
    }
}
</style>