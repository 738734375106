<script>
export default {
    name: "Footer"
}
</script>

<template>
    <div class="footer">

    </div>
</template>

<style>


@media (max-width: 767px) { /* стили для xs-устройств */

}

@media (min-width: 768px) and (max-width: 991px) { /* стили для sm-устройств */

}

@media (min-width: 991px) and (max-width: 1495px) { /* стили для md-устройств */

}

@media (min-width: 1495px) { /* lg устройства */

}
</style>